import { partition, sortByStrings } from "@ameelio/core";
import { Button, SelectInputBase } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Box,
  capitalize,
  Card,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { Entitlement, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { alphabetize } from "@src/lib/Common";
import ConditionalTooltip from "@src/lib/ConditionalTooltip";
import DetailsStack from "@src/lib/DetailsStack";
import Header from "@src/lib/Header";
import featuresForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import { labelPrivacyLevel } from "@src/lib/privacyLabels";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import NewAddEditScheduleModal from "@src/lib/ScheduleTable/AddEditScheduleModal/newModal";
import DeleteScheduleModal from "@src/lib/ScheduleTable/DeleteScheduleModal";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import stripInvalid from "@src/lib/stripInvalid";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  GetFacilitySchedulesDocument,
  GetFacilitySchedulesQuery,
} from "./GetFacilitySchedules.generated";
import ScheduleCard from "./ScheduleCard";

export type Filters = {
  meetingType?: MeetingType[];
  group?: string[];
  privacyLevel?: PrivacyLevel[];
};

const { history } = window;

export default function SchedulesPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const location = useLocation();
  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const [selectedSchedule, setSelectedSchedule] = useState<
    GetFacilitySchedulesQuery["facility"]["schedules"][0] | null
  >(null);
  const [isAddingSchedule, setIsAddingSchedule] = useState<boolean>(false);
  const [isEditingSchedule, setIsEditingSchedule] = useState<boolean>(false);
  const [isDeletingSchedule, setIsDeletingSchedule] = useState<boolean>(false);
  const [loadedOnce, setLoadedOnce] = useState<boolean>(false);

  const [enabledTypes, disabledTypes] = partition(
    Object.values(MeetingType),
    (mt) =>
      facility.features.some((f) => featuresForMeetingType(mt).includes(f)),
  );
  const sortedMeetingTypes = [
    ...alphabetize(enabledTypes),
    ...alphabetize(disabledTypes),
  ];

  // load state from location (forward nav) and history (back nav)
  const [filters, setFilters] = useState<Filters>(() => {
    const stateSchema = Joi.object<{
      meetingType?: MeetingType[];
      group?: string[];
      privacyLevel?: PrivacyLevel[];
    }>({
      meetingType: Joi.array().items(
        Joi.string().valid(...Object.values(MeetingType)),
      ),
      group: Joi.array().items(Joi.string()),
      privacyLevel: Joi.array().items(
        Joi.string().valid(...Object.values(PrivacyLevel)),
      ),
    });
    const historyState = stripInvalid(stateSchema, history.state);
    const locationState = stripInvalid(stateSchema, location.state);
    return { ...historyState, ...locationState };
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  const { data, loading, error, refetch } = useQuery(
    GetFacilitySchedulesDocument,
    {
      fetchPolicy: "cache-first",
      variables: {
        facilityId: facility.id || "",
        meetingType: filters.meetingType ?? null,
        group: filters.group ?? null,
        privacyLevel: filters.privacyLevel ?? null,
      },
      onCompleted: () => {
        if (!loadedOnce) setLoadedOnce(true);
      },
    },
  );

  useEffect(() => {
    void refetch(filters);
  }, [refetch, filters]);

  if (error) throw error;
  if (!data && !loadedOnce && loading) return <SettingsSkeleton />;

  return (
    <>
      <Header
        title={t("Schedules")}
        subtitle={t(
          "Schedules define who gets access to which services on which days.",
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="flex-start"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <SelectInputBase
                disabled={loading}
                size="small"
                aria-label={t("Filter by meeting type")}
                placeholder={t("Filter by meeting type")}
                label={t("Meeting type")}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const value = event.target.value;
                  changeFilter({
                    meetingType:
                      value && value !== "All"
                        ? [value as MeetingType]
                        : undefined,
                  });
                }}
                value={filters.meetingType?.[0] || "All"}
                items={[
                  { value: "All", name: t("All") },
                  ...sortedMeetingTypes.map((mt) => ({
                    value: mt,
                    name: meetingTypeTitle([mt]),
                    disabled: !facility.features.some((f) =>
                      featuresForMeetingType(mt).includes(f),
                    ),
                  })),
                ]}
              />
              <SelectInputBase
                size="small"
                disabled={loading}
                label={t("Location")}
                aria-label={t("Filter by resident location")}
                value={filters.group?.length === 1 ? filters.group : "All"}
                onChange={(event) => {
                  setFilters((filters) => ({
                    ...filters,
                    group:
                      event.target.value === "All"
                        ? undefined
                        : ([event.target.value] as string[]),
                  }));
                }}
                items={
                  facility.groups.length > 1
                    ? [
                        {
                          value: "All",
                          name: t("All"),
                        },
                        ...facility.groups
                          .map((g) => ({
                            value: g.id,
                            key: g.id,
                            name: capitalize(g.name),
                          }))
                          .sort((a, b) => a.name.localeCompare(b.name)),
                      ]
                    : [
                        ...facility.groups.map((g) => ({
                          value: g.id,
                          key: g.id,
                          name: capitalize(g.name),
                        })),
                      ]
                }
              />
              <SelectInputBase
                size="small"
                disabled={loading}
                label={t("Security level")}
                aria-label={t("Filter by security level")}
                value={
                  filters.privacyLevel?.length === 1
                    ? filters.privacyLevel
                    : "All"
                }
                onChange={(event) => {
                  setFilters((filters) => ({
                    ...filters,
                    privacyLevel:
                      event.target.value === "All"
                        ? undefined
                        : ([event.target.value] as PrivacyLevel[]),
                  }));
                }}
                items={[
                  {
                    value: "All",
                    name: t("All"),
                  },
                  ...Object.values(PrivacyLevel)
                    .filter((pl) => pl !== PrivacyLevel.Hidden)
                    .map((pl) => ({
                      value: pl,
                      key: pl,
                      name: labelPrivacyLevel(pl, { titleCase: true }),
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name)),
                ]}
              />
            </ResponsiveColumns>
          </Box>
        </Box>
      </Header>
      <Stack spacing={2} p={2}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
              <SectionTitle>{t("Schedules")}</SectionTitle>
              <ConditionalTooltip
                title={t("You do not have permission to add schedules.")}
                active={!canEdit}
              >
                <Button
                  onClick={() => {
                    setIsAddingSchedule(true);
                  }}
                  variant="outlined"
                  disabled={!canEdit}
                >
                  {t("Add schedule")}
                </Button>
              </ConditionalTooltip>
            </Box>
            <DetailsStack>
              {(!data || loading) && loadedOnce ? (
                <SettingsSkeleton />
              ) : data?.facility.schedules.length === 0 ? (
                <Typography variant="body1" color="text.primary">
                  {t("No schedules found.")}
                </Typography>
              ) : data?.facility.schedules.length ? (
                sortByStrings(data.facility.schedules, (s) => s.name).map(
                  (s) => (
                    <ScheduleCard
                      key={s.id}
                      schedule={s}
                      canEdit={canEdit}
                      onEdit={() => {
                        setSelectedSchedule(s);
                        setIsEditingSchedule(true);
                      }}
                      onDelete={() => {
                        setSelectedSchedule(s);
                        setIsDeletingSchedule(true);
                      }}
                    />
                  ),
                )
              ) : null}
            </DetailsStack>
          </Stack>
        </Card>
      </Stack>
      {isDeletingSchedule && selectedSchedule && (
        <DeleteScheduleModal
          schedule={selectedSchedule}
          onClose={() => setIsDeletingSchedule(false)}
        />
      )}
      {isEditingSchedule && selectedSchedule && (
        <NewAddEditScheduleModal
          schedule={selectedSchedule}
          onClose={() => setIsEditingSchedule(false)}
        />
      )}
      {isAddingSchedule && (
        <NewAddEditScheduleModal onClose={() => setIsAddingSchedule(false)} />
      )}
    </>
  );
}
