import * as Sentry from "@sentry/react";
import { isToday, isTomorrow } from "date-fns";
import i18n from "../i18n/i18nConfig";

export function yearMonthDate(d: Date | number) {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(d);
}

export function inferDateFormat() {
  // Notice that it's actually "unusual" to extract dateFormat from a date.
  /*
    Process is
    - create a new date object for Dec 31st
    - parse to format using yearMonthDate(date) to return either 31/12/2023, or 12/31/2023
    - look whether the generated string starts with 31, thus being DD-MM-YYYY, or 12, thus being MM-DD-YYYY
  */
  const endOfYear = new Date(2023, 11, 31);
  const formattedDate = yearMonthDate(endOfYear);
  return formattedDate.startsWith("31") ? "DD-MM-YYYY" : "MM-DD-YYYY";
}

// formats a ms duration as either HH:MM:SS or MM:SS (but not SS)
export function formatTimer(ms: number) {
  const hhmmss = [
    Math.floor(ms / 3600000) || NaN,
    Math.floor((ms % 3600000) / 60000),
    Math.floor(((ms % 3600000) % 60000) / 1000),
  ]
    .filter((n) => !Number.isNaN(n))
    .map((n) => String(n).padStart(2, "0"));
  return hhmmss.join(":");
}

export const shortTime = new Intl.DateTimeFormat(navigator.language, {
  hour: "numeric",
  minute: "numeric",
});

export function formatTime(d: Date | number) {
  return shortTime.format(d);
}

export const shortTimeAndZone = new Intl.DateTimeFormat([], {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
});

export function formatTimeRange(d: Date | number, d2: Date | number) {
  return `${shortTime.format(d)} - ${shortTimeAndZone.format(d2)}`;
}

const weekdayAndDate = new Intl.DateTimeFormat([], {
  weekday: "long",
  month: "long",
  day: "numeric",
});

export const shortMonthDateFormat = new Intl.DateTimeFormat([], {
  weekday: "long",
  month: "short",
  day: "numeric",
});

export const monthDateYearFormat = new Intl.DateTimeFormat([], {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

/**
 * relativeDate returns values like:
 *
 * - Today, August 3
 * - Tomorrow, August 4
 * - Wednesday, August 5
 */
export function relativeDate(
  d: Date | number,
  customFormat?: Intl.DateTimeFormat,
) {
  const format = customFormat ?? weekdayAndDate;
  const standardFormat = format.format(d);
  const weekday = new Intl.DateTimeFormat([], { weekday: "long" }).format(d);
  return isToday(d)
    ? standardFormat.replace(weekday, i18n.t("Today"))
    : isTomorrow(d)
      ? standardFormat.replace(weekday, i18n.t("Tomorrow"))
      : standardFormat;
}

export function yearShortMonthDateTime(d: Date | number) {
  return new Intl.DateTimeFormat([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(d);
}

export function shortMonthDateTime(d: Date | number) {
  return new Intl.DateTimeFormat([], {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(d);
}

// Returns a string `MMMM ddo` e.g. Jun 17th
export function shortMonthDate(d: Date | number): string {
  return new Intl.DateTimeFormat([], {
    month: "short",
    day: "numeric",
  }).format(d);
}

/*
  Return ISO date representation without considering the Timezone. Useful for Date Of Birth conversion.
 */
export function dateToIso(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

/**
 * Returns a date object without localizing to the user's timezone.
 * Useful when constructing dates to represent birthdays, for example.
 *
 * dateString should be in the format yyyy-mm-dd
 */
export function getRawDateTzUnaware(dateString: string) {
  const dateParts = dateString.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);
  return new Date(year, month - 1, day); // Month is 0-indexed
}

/**
 * Rounds the given milliseconds to the nearest useful unit for display.
 *
 * Waiting for https://github.com/tc39/proposal-intl-duration-format
 */
export function approximateDuration(ms: number): string {
  return ms < 60000
    ? i18n.t("{{count}} seconds", { count: Math.round(ms / 1000) })
    : ms < 3600000
      ? i18n.t("{{count}} minutes", { count: Math.round(ms / 60000) })
      : ms < 86400000
        ? i18n.t("{{count}} hours", { count: Math.round(ms / 3600000) })
        : i18n.t("{{count}} days", { count: Math.round(ms / 86400000) });
}

/**
 * Rounds the given milliseconds to the nearest minute.
 *
 * Waiting for https://github.com/tc39/proposal-intl-duration-format
 */
export function durationRoundedToMinutes(ms: number): string {
  return i18n.t("{{count}} minutes", { count: Math.round(ms / 60000) });
}

const MINUTE = 60 * 1000;
const HOUR = 3600 * 1000;
const DAY = 68400 * 1000;

/**
 * Creates a very small elapsed time string, e.g. '12s', '8m', '3h' '5d'...
 * @param d date object or number representing ms from epoch
 * @returns string
 */
export function microElapsedTimeString(d: Date | number): string {
  // We know some older browsers do not support Intl,
  // gracefully degrade in this case
  if (!("Intl" in window)) return "";
  try {
    const { t } = i18n;
    const diff = new Date().getTime() - new Date(d).getTime();
    if (diff < MINUTE) return t("now");

    const rtf = new Intl.RelativeTimeFormat("en", { style: "narrow" });
    const args: [number, Intl.RelativeTimeFormatUnit] =
      diff < HOUR
        ? [-diff / MINUTE, "minute"]
        : diff < DAY
          ? [-diff / HOUR, "hour"]
          : [-diff / DAY, "day"];
    const intPart = rtf
      .formatToParts(...args)
      .filter((p) => p.type === "integer")[0] as Exclude<
      Intl.RelativeTimeFormatPart,
      { type: "literal" }
    >;
    const i18nUnit =
      intPart.unit === "second"
        ? t("second_abbr")
        : intPart.unit === "minute"
          ? t("minute_abbr")
          : intPart.unit === "hour"
            ? t("hour_abbr")
            : intPart.unit === "day"
              ? t("day_abbr")
              : null;
    return intPart.value && i18nUnit
      ? `${intPart.value}${i18nUnit.slice(0, 1)}`
      : "";
  } catch (e) {
    Sentry.captureException(e);
    return "";
  }
}

// 00:00:00 format
export function hoursMinutesSecondsDigitsLabel(ms: number | undefined): string {
  if (!ms) return "00:00:00";
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const remainder1 = ms - hours * 60 * 60 * 1000;
  const minutes = Math.floor(remainder1 / (60 * 1000));
  const remainder2 = remainder1 - minutes * 60 * 1000;
  const seconds = Math.floor(remainder2 / 1000);
  const hoursDigits = `${hours}`.padStart(2, "0");
  const minutesDigits = `${minutes}`.padStart(2, "0");
  const secondsDigits = `${seconds}`.padStart(2, "0");
  return `${hoursDigits}:${minutesDigits}:${secondsDigits}`;
}

export function hoursMinutesSecondsTextLabel(ms?: number | null): string {
  if (!ms) return "0 seconds";
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutesRemaining = Math.floor(
    (ms - hours * 60 * 60 * 1000) / (60 * 1000),
  );
  const secondsRemaining = Math.floor(
    (ms - hours * 60 * 60 * 1000 - minutesRemaining * 60 * 1000) / 1000,
  );
  if (hours === 0 && minutesRemaining === 0)
    return i18n.t("{{count}} seconds", { count: secondsRemaining });
  if (hours === 0)
    return i18n.t("{{count}}{{colonSeconds}} mins", {
      count: minutesRemaining,
      colonSeconds:
        secondsRemaining > 0
          ? `:${`${secondsRemaining}`.padStart(2, "0")}`
          : "",
    });
  const hoursFormatted =
    hours > 0 ? i18n.t("{{count}} hrs", { count: hours }) : "";
  const minutesFormatted =
    minutesRemaining > 0
      ? i18n.t("{{count}} mins", { count: minutesRemaining })
      : "";
  const secondsFormatted =
    secondsRemaining > 0
      ? i18n.t("{{count}} secs", { count: secondsRemaining })
      : "";
  return `${hoursFormatted}${
    hoursFormatted && minutesFormatted ? " " : ""
  }${minutesFormatted}${
    (hoursFormatted || minutesFormatted) && secondsFormatted ? " " : ""
  }${secondsFormatted}`;
}

export function getLocalizedDateFormatString() {
  return inferDateFormat() === "DD-MM-YYYY"
    ? i18n.t("DD/MM/YYYY")
    : i18n.t("MM/DD/YYYY");
}
