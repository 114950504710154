import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { evictItem } from "@src/api/client";
import {
  Connection,
  Entitlement,
  Inmate,
  Meeting,
  MeetingStatus,
  Visitor,
} from "@src/api/graphql";
import { RejectOrTerminateMeetingsDocument } from "@src/graphql/RejectOrTerminateMeetings.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import useEntitlement from "@src/lib/useEntitlement";
import { isRequired } from "@src/lib/validate";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type MeetingToReject = Pick<Meeting, "id" | "meetingType" | "status"> & {
  inmates: Pick<Inmate, "id" | "fullName">[];
  visitors: Pick<Visitor, "id" | "fullName">[];
};

export type ConnectionToReject = Pick<Connection, "id"> & {
  inmate: Pick<Inmate, "id" | "fullName">;
  visitor: Pick<Visitor, "id" | "fullName">;
};

type Props = {
  meetings: MeetingToReject[];
  onClose: (allDeclined: boolean) => void;
  onDeclined?: (failedIds: string[]) => void;
};

type FormData = {
  reason: string;
  otherReason: string;
};

export default function DeclineMeetingsModal({
  meetings,
  onClose,
  onDeclined,
}: Props) {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      reason: "",
      otherReason: "",
    },
  });
  // we need the reason to reveal the text box for a custom reason
  const reason = watch("reason");

  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const OTHER = t("Other");

  const isPendingApproval = meetings.every(
    (m) => m.status === MeetingStatus.PendingApproval,
  );
  const REJECTION_REASONS = isPendingApproval
    ? [
        t("Resident has reached the maximum number of allowed visits."),
        t("Resident is unable to visit at this time."),
        t("The unit is on lockdown. All visitations have been declined."),
        OTHER,
      ]
    : [
        t("The unit is on lockdown. All visitations have been canceled."),
        t("Due to weather, we have to cancel all visitation appointments."),
        OTHER,
      ];
  const handleApolloError = useApolloErrorHandler();

  const [rejectOrTerminateMeetings] = useMutation(
    RejectOrTerminateMeetingsDocument,
    {
      onError: handleApolloError,
      onCompleted: (data) => {
        const { failures } = data.rejectOrTerminateMeetings;
        // Only want to enable sending requests again for
        // meetings that failed to be canceled
        if (onDeclined) onDeclined(failures);
        if (failures.length) {
          snackbarContext.alert(
            "error",
            isPendingApproval && failures.length > 1
              ? t(
                  "{{count}} meetings were not declined successfully. Please try again.",
                  { count: failures.length },
                )
              : isPendingApproval
                ? t(
                    "{{count}} meeting was not declined successfully. Please try again.",
                    { count: failures.length },
                  )
                : failures.length > 1
                  ? t(
                      "{{count}} meetings were not canceled successfully. Please try again.",
                      { count: failures.length },
                    )
                  : t(
                      "{{count}} meeting was not canceled successfully. Please try again.",
                      { count: failures.length },
                    ),
          );
          // Only evict meetings from the cache that were
          // successfully declined/canceled
          for (let i = 0; i < meetings.length; i++) {
            if (!failures.includes(meetings[i].id)) evictItem(meetings[i]);
          }
          return;
        }
        snackbarContext.alert(
          "success",
          meetings.length > 1 && isPendingApproval
            ? t("The meetings have been successfully declined.")
            : meetings.length > 1
              ? t("The meetings have been successfully canceled.")
              : isPendingApproval
                ? t("The meeting has been successfully declined.")
                : t("The meeting has been successfully canceled."),
        );
        // Evict all meetings from the cache as
        // they were all canceled successfully
        for (let i = 0; i < meetings.length; i++) {
          evictItem(meetings[i]);
        }
        // Then hide the dialog
        onClose(true);
      },
    },
  );
  const doSubmit = async (data: FormData) => {
    await rejectOrTerminateMeetings({
      variables: {
        input: {
          meetingIds: meetings.map((m) => m.id),
          reason: data.reason === OTHER ? data.otherReason : data.reason,
        },
      },
    });
  };

  return (
    <Dialog
      title={
        meetings.length > 1 && isPendingApproval
          ? t("Decline {{count}} meetings", {
              count: meetings.length,
            })
          : meetings.length > 1
            ? t("Cancel {{count}} meetings", {
                count: meetings.length,
              })
            : isPendingApproval
              ? t("Decline meeting")
              : t("Cancel meeting")
      }
      onClose={() => onClose(false)}
    >
      <DialogContent>
        {!isSubmitting ? (
          <form
            onSubmit={handleSubmit(doSubmit)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Box mb={2}>
              <Typography variant="subtitle2" color="text.primary">
                {meetings.length > 1 && isPendingApproval
                  ? t(
                      "Please provide a reason for declining the meetings so we can inform the participants.",
                    )
                  : isPendingApproval
                    ? t(
                        "Please provide a reason for declining the meeting so we can inform the participants.",
                      )
                    : t(
                        "Please provide a reason for the cancellation so we can inform the participants.",
                      )}
              </Typography>
            </Box>
            <Controller
              control={control}
              name="reason"
              rules={isRequired(t("Please select a reason."))}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {REJECTION_REASONS.map((reason) => (
                    <FormControlLabel
                      key={reason}
                      control={<Radio />}
                      value={reason}
                      label={
                        <Typography variant="body2" color="text.primary">
                          {reason}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {reason === OTHER && (
              <TextInput
                control={control}
                name="otherReason"
                rules={isRequired(t("Please provide a reason."))}
                multiline
                rows={2}
                sx={{ ml: 4, flex: 1 }}
              />
            )}
          </form>
        ) : (
          <Stack gap={4}>
            <Typography variant="body1" color="text.primary">
              {t("Request in progress. This may take a few moments.")}
            </Typography>
            <LinearProgress />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={() => onClose(false)}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          dangerous
          disabled={!isValid || !canManageVisitorsAndMeetings}
          disabledReason={
            !canManageVisitorsAndMeetings
              ? t("You do not have permission to decline meetings.")
              : undefined
          }
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Confirm")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
