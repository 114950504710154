import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Close } from "@mui/icons-material";
import { Card, Checkbox, Link, Stack, Typography } from "@mui/material";
import {
  FacilityApprovedPhone,
  Inmate,
  Interval,
  MeetingStatus,
  MeetingType,
  PhoneCall,
} from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import InmateLink from "@src/lib/InmateLink";
import MeetingContextMenu from "@src/lib/MeetingContextMenu";
import MeetingStatusTag from "@src/lib/MeetingStatusTag";
import PageLoader from "@src/lib/PageLoader";
import PhoneCallStatusTag from "@src/lib/PhoneCallStatusTag";
import MeetingsTableMeeting from "@src/lib/Profiles/MeetingsTableMeeting";
import { isMonitored } from "@src/lib/securityFeatureUtils.ts";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useBoolean from "@src/lib/useBoolean";
import { CALL_STARTUP_LEAD_TIME } from "@src/lib/useLiveCalls";
import useNow from "@src/lib/useNow";
import VisitorList from "@src/lib/VisitorList";
import { subMilliseconds } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeclineMeetingsModal, {
  MeetingToReject,
} from "../MeetingRequestsPage/DeclineMeetingsModal";

type PhoneCallRecord = Pick<PhoneCall, "id" | "security" | "status"> & {
  interval: Pick<Interval, "startAt">;
  inmates: Pick<Inmate, "id" | "inmateIdentification" | "fullName">[];
  facilityApprovedPhone: Pick<FacilityApprovedPhone, "id" | "name" | "number">;
};

function isPhoneCallRecord(
  record: PhoneCallRecord | MeetingsTableMeeting,
): record is PhoneCallRecord {
  return "facilityApprovedPhone" in record;
}

interface Props {
  meetings: (PhoneCallRecord | MeetingsTableMeeting)[];
  loading?: boolean;
  enableBatchCancel: boolean;
}

export default function ScheduledMeetingsTable({
  meetings,
  loading,
  enableBatchCancel,
}: Props) {
  const { t } = useTranslation();
  const now = useNow({ updateDelay: 5000 });
  const navigate = useNavigate();

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
  });
  const [selected, setSelected] = useState<MeetingToReject[]>([]);
  const rejecting = useBoolean();

  const selectAll = (e: React.MouseEvent) => {
    (e.target as HTMLInputElement).checked
      ? setSelected((prev) => [
          ...prev,
          ...(currentPageData.filter(
            (m) =>
              !prev.map((m) => m.id).includes(m.id) && !isPhoneCallRecord(m),
          ) as MeetingToReject[]),
        ])
      : setSelected((prev) =>
          prev.filter((m) => !currentPageData.map((m) => m.id).includes(m.id)),
        );
  };

  const selectRecord = (meetingId: string) => () => {
    if (selected.map((m) => m.id).includes(meetingId))
      setSelected((prev) => prev.filter((m) => m.id !== meetingId));
    else
      setSelected((prev) => [
        ...prev,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        meetings.find((m) => m.id === meetingId)! as MeetingToReject,
      ]);
  };

  // used to de-select all records when
  // the filters change
  const meetingsRef = useRef<(PhoneCallRecord | MeetingToReject)[]>(meetings);
  useEffect(() => {
    if (
      !meetings.length ||
      !meetings.every((m) =>
        meetingsRef.current.map((m) => m.id).includes(m.id),
      )
    )
      setSelected([]);
    meetingsRef.current = meetings;
  }, [meetings]);

  if (loading) return <PageLoader />;

  return (
    <>
      {rejecting.current && (
        <DeclineMeetingsModal
          meetings={selected}
          onClose={(allDeclined) => {
            rejecting.disable();
            // Reset to the first page
            if (allDeclined) onPageChange(0);
          }}
          onDeclined={(failures: string[]) => {
            // Only leave meetings that failed to be canceled in the selected list
            setSelected(() => selected.filter((m) => failures.includes(m.id)));
          }}
        />
      )}
      {enableBatchCancel && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.primary">
            {t(
              "Now you can select multiple meetings and cancel them all at once. Use the checkbox in the table header to select/de-select all records on the current page, or you can select specific rows with each row's checkbox.",
            )}
          </Typography>
        </Alert>
      )}
      {!!selected.length && (
        <Card variant="outlined" sx={{ borderRadius: "8px", marginBottom: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1}
          >
            <Typography variant="body1" color="text.primary">
              {selected.length === 1
                ? t("{{count}} record selected", { count: selected.length })
                : t("{{count}} records selected", { count: selected.length })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={rejecting.enable}>
                {selected.length === 1 &&
                selected[0].status === MeetingStatus.PendingApproval
                  ? t("Decline meeting")
                  : selected.every(
                        (m) => m.status === MeetingStatus.PendingApproval,
                      )
                    ? t("Decline meetings")
                    : selected.length === 1
                      ? t("Cancel meeting")
                      : t("Cancel meetings")}
              </Button>
              <IconButton
                name="close"
                ariaLabel={t("Close")}
                onClick={() => setSelected([])}
              >
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </Card>
      )}
      <TableContainer>
        <Table aria-label={t("Scheduled meetings table")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Start time")}</TableCell>
              <TableCell>{t("Residents")}</TableCell>
              <TableCell>{t("Visitors")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell>{t("Resource")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
              {enableBatchCancel &&
                !meetings.every((m) => isPhoneCallRecord(m)) && (
                  <TableCell>
                    <Checkbox
                      checked={
                        !!selected.length &&
                        currentPageData.every((m) =>
                          selected.map((m) => m.id).includes(m.id),
                        )
                      }
                      onClick={selectAll}
                    />
                  </TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {meetings.length === 0 && <TableNoData colSpan={7} />}
            {meetings.length > 0 &&
              currentPageData.map((meeting) => {
                return (
                  <TableRow key={meeting.id}>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "date")}
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "time")}
                    </TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {meeting.inmates.map((inmate) => (
                          <InmateLink key={inmate.id} inmate={inmate} />
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <>
                          {meeting.facilityApprovedPhone.name} (
                          {meeting.facilityApprovedPhone.number})
                        </>
                      ) : (
                        <VisitorList
                          visitors={meeting.visitors}
                          unregisteredGuests={meeting.unregisteredGuests}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <PhoneCallStatusTag status={meeting.status} />
                      ) : meeting.status === MeetingStatus.Live &&
                        now.getTime() >=
                          subMilliseconds(
                            meeting.interval.startAt,
                            CALL_STARTUP_LEAD_TIME,
                          ).getTime() &&
                        meeting.meetingType !== MeetingType.InPersonVisit &&
                        isMonitored(meeting.security) ? (
                        <Link
                          onClick={() =>
                            navigate(
                              meeting.meetingType === MeetingType.VoiceCall
                                ? `/live/voice-calls`
                                : `/live/video-calls`,
                            )
                          }
                        >{`${getMeetingStatusLabelByType(
                          meeting.status,
                        ).toUpperCase()} - ${t("Monitor now")}`}</Link>
                      ) : (
                        <MeetingStatusTag
                          status={meeting.status}
                          meetingType={meeting.meetingType}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) || !meeting.kiosk
                        ? "-"
                        : meeting.kiosk.name}
                    </TableCell>
                    {enableBatchCancel && !isPhoneCallRecord(meeting) ? (
                      <>
                        <TableCell>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => navigate(`/meetings/${meeting.id}`)}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {t("See details")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            onClick={selectRecord(meeting.id)}
                            checked={selected
                              .map((m) => m.id)
                              .includes(meeting.id)}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <TableCell>
                        {isPhoneCallRecord(meeting) ? null : (
                          <SearchMeetingsTableActionButtons meeting={meeting} />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={meetings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
}

const SearchMeetingsTableActionButtons: React.FC<{
  meeting: MeetingsTableMeeting;
}> = ({ meeting }) => {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate(`/meetings/${meeting.id}`)}
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("See details")}
      </Button>
      <MeetingContextMenu meeting={meeting} />
    </Stack>
  );
};
