import { uniq } from "@ameelio/core";
import {
  DialogProps as BaseProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MultiSelectChipInput,
  SelectInput,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { appendItem } from "@src/api/client";
import { Entitlement, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilitySchedulesQuery } from "@src/graphql/GetFacilitySchedules.generated";
import DateInput from "@src/lib/DateInput";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import featuresForMeetingType, { labelMeetingType } from "@src/lib/meeting";
import { labelPrivacyLevel } from "@src/lib/privacyLabels";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import { hasMaxLength, isRequired, mergeRules } from "@src/lib/validate";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { DialogSkeleton } from "../../closet";
import { parseTimeslot } from "../../parseTime";
import { CreateScheduleDocument } from "./CreateSchedule.generated";
import DaysInput from "./DaysInput";
import { GetFacilityGroupsAndKiosksDocument } from "./GetFacilityGroupsAndKiosks.generated";
import TimeSlotsInput, { IdTimeslot } from "./TimeSlotsInput";
import { UpdateScheduleDocument } from "./UpdateSchedule.generated";

type Schedule = GetFacilitySchedulesQuery["facility"]["schedules"][number];

export type Timeslot = {
  hour: number;
  minute: number;
  duration: number;
};

export type TextFormData = {
  scheduleName: string;
  meetingTypes?: MeetingType[];
  privacyLevel?: PrivacyLevel;
  groupIds: string[];
  kioskIds: string[];
  days: number[];
  timeslots: IdTimeslot[];
  startsOn: string;
  endsOn: string;
};

type Props = Omit<BaseProps, "open" | "onSubmit" | "title" | "children"> & {
  schedule?: Schedule;
  onClose: () => void;
};

function zeroPad(s: string, n: number): string {
  if (s.length < n) {
    return zeroPad("0" + s, n - 1);
  }
  return s;
}

function formatInterval({
  hour,
  minute,
  duration,
}: {
  hour: number;
  minute: number;
  duration: number;
}): [string, string] {
  const endTotalMinutes =
    hour * 60 + minute + Math.floor(duration / (60 * 1000));
  const endHours = Math.floor(endTotalMinutes / 60);
  const endMinutes = endTotalMinutes % 60;
  return [
    `${zeroPad(hour.toString(), 2)}:${zeroPad(minute.toString(), 2)}`,
    `${zeroPad(endHours.toString(), 2)}:${zeroPad(endMinutes.toString(), 2)}`,
  ];
}

function daySlotStrings(arr: Schedule["meetingSlots"]): [string, string][] {
  if (arr.length === 0) return [];

  const representativeDay = arr[0].day;
  return arr
    .filter((x) => x.day === representativeDay)
    .map((s) => ({
      hour: s.hour,
      minute: s.minute,
      duration: s.duration,
    }))
    .sort((a, b) =>
      a.hour < b.hour
        ? -1
        : a.hour > b.hour
          ? 1
          : a.minute < b.minute
            ? -1
            : a.minute > b.minute
              ? 1
              : 0,
    )
    .map(formatInterval);
}

function definedAndNoOverlaps(parsedSlots: (Timeslot | null)[]) {
  if (parsedSlots.some((x) => !x)) {
    return false;
  }

  const numerical = (parsedSlots as Timeslot[]).map((x) => ({
    start: x.hour * 60 + x.minute,
    end: x.hour * 60 + x.minute + x.duration / (60 * 1000),
  }));

  numerical.sort((a, b) => a.start - b.start);

  // Invalid if a time slot does not have positive duration
  if (numerical.some((x) => x.end <= x.start)) {
    return false;
  }

  // Invalid if slots overlap
  if (numerical.some((x, i) => i > 1 && numerical[i - 1].end > x.start)) {
    return false;
  }

  return true;
}

function stringsDefinedAndNoOverlaps(strings: IdTimeslot[]) {
  return definedAndNoOverlaps(
    strings.map(({ start, end }) => parseTimeslot(start, end)),
  );
}

function daysNonEmpty(days: number[]) {
  return days.length > 0;
}

function timeslotsNonEmpty(timeslots: IdTimeslot[]) {
  return timeslots.length > 0;
}

export default function NewAddEditScheduleModal({
  onClose,
  schedule,
  ...rest
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const snackbarContext = useSnackbarContext();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onChange",
    defaultValues: {
      scheduleName: schedule?.name || "",
      meetingTypes: schedule?.meetingTypes.length ? schedule.meetingTypes : [],
      privacyLevel: schedule?.privacyLevels[0],
      groupIds: schedule?.groups.map((g) => g.id) || [],
      kioskIds: schedule?.kiosks.map((k) => k.id) || [],
      days: schedule ? uniq(schedule.meetingSlots.map((s) => s.day)) : [],
      timeslots: schedule
        ? daySlotStrings(schedule.meetingSlots).map(([start, end]) => ({
            id: uuid(),
            start,
            end,
          }))
        : [],
      startsOn: schedule?.startsOn || "",
      endsOn: schedule?.endsOn || "",
    },
  });
  const selectedMeetingTypes = watch("meetingTypes");
  const selectedPrivacyLevel = watch("privacyLevel");

  // used to determine what meeting types can be combined
  // with others when creating/editing a schedule
  const isMeetingTypeSelectable = useCallback(
    (mt: MeetingType) => {
      if (mt === MeetingType.VoiceCall)
        return (
          !selectedMeetingTypes ||
          ![
            MeetingType.InPersonVisit,
            MeetingType.VideoCall,
            MeetingType.Webinar,
          ].some((mt) => selectedMeetingTypes.includes(mt))
        );
      if (mt === MeetingType.InPersonVisit)
        return (
          !selectedMeetingTypes ||
          ![MeetingType.Webinar, MeetingType.VoiceCall].some((mt) =>
            selectedMeetingTypes.includes(mt),
          )
        );
      if (mt === MeetingType.VideoCall)
        return (
          !selectedMeetingTypes ||
          ![MeetingType.Webinar, MeetingType.VoiceCall].some((mt) =>
            selectedMeetingTypes.includes(mt),
          )
        );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (mt === MeetingType.Webinar)
        return (
          !selectedMeetingTypes ||
          ![
            MeetingType.InPersonVisit,
            MeetingType.VideoCall,
            MeetingType.VoiceCall,
          ].some((mt) => selectedMeetingTypes.includes(mt))
        );
    },
    [selectedMeetingTypes],
  );

  useEffect(() => {
    setTimeout(() => {
      setFocus("scheduleName");
    }, 0);
  }, [setFocus]);
  const handleApolloError = useApolloErrorHandler();

  const [updateSchedule] = useMutation(UpdateScheduleDocument, {
    onError: handleApolloError,
    onCompleted: () =>
      snackbarContext.alert("success", t("Schedule modified successfully")),
  });

  const [createSchedule] = useMutation(CreateScheduleDocument, {
    onError: handleApolloError,
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ __typename: "Facility", id: facility.id }),
          fields: {
            schedules: appendItem(data.createSchedule.schedule),
          },
        });
      }
    },
    onCompleted: () =>
      snackbarContext.alert("success", t("Schedule created successfully")),
  });

  const { data: groupsKiosksData } = useQuery(
    GetFacilityGroupsAndKiosksDocument,
    {
      variables: {
        facilityId: facility.id,
      },
      onError: handleApolloError,
    },
  );

  if (!groupsKiosksData) return <DialogSkeleton />;

  const { groups, kiosks: allKiosks } = groupsKiosksData.facility;

  const kiosks = allKiosks.filter(
    (k) => !k.meetingType || selectedMeetingTypes?.includes(k.meetingType),
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const parsedTimeslots = data.timeslots
      .map(({ start, end }) => parseTimeslot(start, end))
      .filter((x) => x) as Timeslot[];

    if (!parsedTimeslots.length) {
      snackbarContext.alert(
        "error",
        "Please add at least one day and time slot.",
      );
      return;
    }

    // Validations
    if (!data.scheduleName) {
      snackbarContext.alert("error", t("Please provide a schedule name."));
      return;
    }
    if (!selectedMeetingTypes?.length) {
      snackbarContext.alert(
        "error",
        t("Please select at least one meeting type."),
      );
      return;
    }
    // Non-webinar schedules must specify a privacy level
    if (
      selectedMeetingTypes.some((mt) => mt !== MeetingType.Webinar) &&
      !selectedPrivacyLevel
    ) {
      snackbarContext.alert("error", t("Please select a security level."));
      return;
    }
    // Video calls and in-person visits require a group
    if (
      selectedMeetingTypes.some(
        (mt) =>
          [MeetingType.VideoCall, MeetingType.InPersonVisit].includes(mt) &&
          !data.groupIds.length,
      )
    ) {
      snackbarContext.alert("error", t("Please select at least one location."));
      return;
    }
    // Video calls and in-person visits require a meeting resource
    if (
      selectedMeetingTypes.some(
        (mt) =>
          [MeetingType.VideoCall, MeetingType.InPersonVisit].includes(mt) &&
          !data.kioskIds.length,
      )
    ) {
      snackbarContext.alert(
        "error",
        t("Please select at least one meeting resource."),
      );
      return;
    }

    // type narrowing (selectedPrivacyLevel will exist when it cannot be undefined below)
    if (!selectedPrivacyLevel) return;

    if (schedule) {
      await updateSchedule({
        variables: {
          input: {
            scheduleId: schedule.id,
            name: data.scheduleName,
            groupIds: data.groupIds,
            kioskIds: data.kioskIds,
            privacyLevels: [
              selectedMeetingTypes.includes(MeetingType.Webinar)
                ? PrivacyLevel.Monitored
                : selectedPrivacyLevel,
            ],
            meetingSlots: data.days.flatMap((day) =>
              parsedTimeslots.map((slot) => ({
                day,
                hour: slot.hour,
                minute: slot.minute,
                duration: slot.duration,
              })),
            ),
            startsOn: data.startsOn || null,
            endsOn: data.endsOn || null,
          },
        },
      });
    } else {
      await createSchedule({
        variables: {
          input: {
            facilityId: facility.id,
            name: data.scheduleName,
            meetingTypes: selectedMeetingTypes,
            // Webinars are restricted to the monitored privacy level
            privacyLevels: [
              selectedMeetingTypes.includes(MeetingType.Webinar)
                ? PrivacyLevel.Monitored
                : selectedPrivacyLevel,
            ],
            groupIds: data.groupIds,
            kioskIds: data.kioskIds,
            meetingSlots: data.days.flatMap((day) =>
              parsedTimeslots.map((slot) => ({
                day,
                hour: slot.hour,
                minute: slot.minute,
                duration: slot.duration,
              })),
            ),
            startsOn: data.startsOn || null,
            endsOn: data.endsOn || null,
          },
        },
      });
    }

    onClose();
  });

  return (
    <Dialog
      title={
        schedule
          ? canEdit
            ? t("Editing Schedule")
            : t("Schedule Details")
          : t("Add Schedule")
      }
      onClose={onClose}
      {...rest}
      fullWidth
    >
      <form onSubmit={onFormSubmit}>
        <DialogContent sx={{ pt: 0 }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            sx={{ width: "100%" }}
            variant="fullWidth"
          >
            <Tab label={t("Information")} />
            <Tab label={t("Times")} />
          </Tabs>
          {tabIndex === 0 && (
            <Stack spacing={2} sx={{ pt: 2 }}>
              <TextInput
                control={control}
                name="scheduleName"
                label={t("Schedule Name")}
                disabled={!canEdit}
                rules={mergeRules(
                  isRequired(t("Please provide a schedule name.")),
                  hasMaxLength(100, t("Name is too long.")),
                )}
              />
              <MultiSelectChipInput
                name="meetingTypes"
                aria-label={t("Meeting types")}
                label={t("Meeting types")}
                control={control}
                rules={{
                  validate: {
                    nonEmpty: (meetingTypes) =>
                      !!meetingTypes?.length ||
                      t("Please select at least one meeting type."),
                  },
                }}
                items={Object.values(MeetingType)
                  .filter((mt) =>
                    facility.features.some((f) =>
                      featuresForMeetingType(mt).includes(f),
                    ),
                  )
                  .map((mt) => ({
                    value: mt,
                    name: labelMeetingType(mt, { titleCase: true }),
                    disabled: !isMeetingTypeSelectable(mt),
                    helperText: !isMeetingTypeSelectable(mt)
                      ? t(
                          "Cannot be combined with the other selected meeting type(s) in a single schedule.",
                        )
                      : undefined,
                  }))}
                disabled={!canEdit || !!schedule}
              />
              {!selectedMeetingTypes?.includes(MeetingType.Webinar) && (
                <SelectInput
                  name="privacyLevel"
                  aria-label={t("Security level")}
                  label={t("Security level")}
                  control={control}
                  rules={{
                    validate: {
                      nonEmpty: (privacyLevel) =>
                        selectedMeetingTypes?.some(
                          (mt) => mt !== MeetingType.Webinar,
                        ) && !privacyLevel
                          ? t("Please select a security level.")
                          : true,
                    },
                  }}
                  items={Object.values(PrivacyLevel)
                    .filter((pl) => pl !== PrivacyLevel.Hidden)
                    .map((pl) => ({
                      key: pl,
                      value: pl,
                      name: labelPrivacyLevel(pl, { titleCase: true }),
                    }))}
                  disabled={!canEdit}
                />
              )}
              {selectedMeetingTypes?.some(
                (mt) => mt !== MeetingType.Webinar,
              ) && (
                <MultiSelectChipInput
                  name="groupIds"
                  aria-label={t("Resident locations")}
                  label={t("Resident locations")}
                  control={control}
                  rules={{
                    validate: {
                      nonEmpty: (locations) =>
                        selectedMeetingTypes.some(
                          (mt) => mt !== MeetingType.Webinar,
                        ) && !locations.length
                          ? t("Please select at least one location.")
                          : true,
                    },
                  }}
                  items={groups
                    .map(({ id, name }) => ({ value: id, name }))
                    .sort((a, b) => a.name.localeCompare(b.name))} // Sort alphabetically
                  disabled={!canEdit}
                />
              )}
              {selectedMeetingTypes?.some((mt) =>
                [MeetingType.VideoCall, MeetingType.InPersonVisit].includes(mt),
              ) && (
                <MultiSelectChipInput
                  name="kioskIds"
                  aria-label={t("Meeting resources")}
                  label={t("Meeting resources")}
                  control={control}
                  rules={{
                    validate: {
                      nonEmpty: (locations) =>
                        selectedMeetingTypes.some(
                          (mt) =>
                            ![
                              MeetingType.Webinar,
                              MeetingType.VoiceCall,
                            ].includes(mt),
                        ) && !locations.length
                          ? t("Please select at least one resource.")
                          : true,
                    },
                  }}
                  items={kiosks.map(({ id, name }) => ({ value: id, name }))}
                  disabled={!canEdit}
                />
              )}
              <Stack direction="row" spacing={2}>
                <DateInput
                  control={control}
                  name="startsOn"
                  label={t("Starts on")}
                  disabled={!canEdit}
                  style={{ width: "100%" }}
                />
                <DateInput
                  control={control}
                  name="endsOn"
                  label={t("Ends after")}
                  disabled={!canEdit}
                  style={{ width: "100%" }}
                />
              </Stack>
            </Stack>
          )}
          {tabIndex === 1 && (
            <Stack spacing={3} sx={{ width: "100%", p: 3 }}>
              <Box
                sx={{
                  display: "grid",
                  alignItems: "center",
                  width: "100%",
                  gridTemplateColumns: (theme) => `30% 1fr ${theme.spacing(3)}`,
                }}
              >
                <Box>
                  <Typography variant="subtitle1">{t("Days")}</Typography>
                </Box>
                <Box>
                  <DaysInput
                    name="days"
                    control={control}
                    disabled={!canEdit}
                    rules={{
                      validate: {
                        nonEmpty: daysNonEmpty,
                      },
                    }}
                  />
                </Box>
              </Box>

              <Divider />

              <Box
                sx={{
                  display: "grid",
                  alignItems: "center",
                  width: "100%",
                  gridTemplateColumns: "30% 1fr",
                }}
              >
                <Box>
                  <Typography variant="subtitle1">{t("Times")}</Typography>
                </Box>
                <Box>
                  <TimeSlotsInput
                    name="timeslots"
                    control={control}
                    rules={{
                      validate: {
                        stringsDefinedAndNoOverlaps,
                        nonEmpty: timeslotsNonEmpty,
                      },
                    }}
                    disabled={!canEdit}
                    disabledText={t(
                      "You do not have permission to edit schedules",
                    )}
                  />
                </Box>
              </Box>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {canEdit ? (
            <>
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t("Cancel")}
              </Button>
              <SubmitButton disabled={isSubmitting} submitting={isSubmitting}>
                {t("Save")}
              </SubmitButton>
            </>
          ) : (
            <Button variant="contained" autoFocus onClick={onClose}>
              {t("Close")}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
