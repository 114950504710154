import {
  Button,
  ButtonProps,
  ConfirmDialog,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Meeting } from "@src/api/graphql";
import { RejectOrTerminateMeetingsDocument } from "@src/graphql/RejectOrTerminateMeetings.generated";
import ContextMenuItem from "@src/lib/ContextMenuItem";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useTranslation } from "react-i18next";
import useBoolean from "./useBoolean";

type Props = ButtonProps & {
  meeting: Pick<Meeting, "id" | "status">;
  displayAsMenuItem?: boolean;
};

export default function EndMeetingButton({
  meeting,
  displayAsMenuItem,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const endMeetingDialog = useBoolean();
  const handleApolloError = useApolloErrorHandler();

  const [rejectOrTerminateMeetings, { loading: submitting }] = useMutation(
    RejectOrTerminateMeetingsDocument,
    {
      onError: handleApolloError,
      onCompleted: () => {
        snackbarContext.alert(
          "success",
          t("The meeting has been successfully ended."),
        );
        // Then hide the dialog
        endMeetingDialog.disable();
      },
    },
  );

  async function endMeeting() {
    await rejectOrTerminateMeetings({
      variables: {
        input: {
          meetingIds: [meeting.id],
          reason: "Meeting ended early by facility staff.",
        },
      },
    });
  }

  return (
    <>
      {endMeetingDialog.current && (
        <ConfirmDialog
          variant="remove"
          description={t(
            "This will immediately end the meeting for all participants and send notifications.",
          )}
          onCancel={endMeetingDialog.disable}
          confirmText={t("Yes, end meeting")}
          onConfirm={async () => {
            // Invoke the callback
            await endMeeting();
          }}
        />
      )}
      {displayAsMenuItem ? (
        <ContextMenuItem
          key="end-meeting"
          disabled={submitting}
          color="error"
          onClick={endMeetingDialog.enable}
        >
          {t("End meeting")}
        </ContextMenuItem>
      ) : (
        <Button
          color="error"
          variant="outlined"
          disabled={submitting}
          onClick={endMeetingDialog.enable}
          {...rest}
        >
          {t("End meeting")}
        </Button>
      )}
    </>
  );
}
