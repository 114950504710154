import { uniq } from "@ameelio/core";
import { ContextMenu } from "@ameelio/ui";
import {
  DateRangeOutlined,
  EventOutlined,
  LockOutlined,
  RemoveRedEyeOutlined,
  RoomOutlined,
} from "@mui/icons-material";
import { Box, Card, MenuItem, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { MeetingType, PrivacyLevel, Slot } from "@src/api/graphql";
import { alphabetize } from "@src/lib/Common";
import { labelMeetingType } from "@src/lib/meeting";
import { labelPrivacyLevel } from "@src/lib/privacyLabels";
import { hourMinutes, yearShortMonthDate } from "@src/lib/Time";
import { getRawDateTzUnaware } from "@src/lib/timeFormats";
import { isPast } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GetFacilitySchedulesQuery } from "./GetFacilitySchedules.generated";

type Props = {
  schedule: GetFacilitySchedulesQuery["facility"]["schedules"][number];
  canEdit: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

export default function ScheduleCard({
  schedule,
  canEdit,
  onEdit,
  onDelete,
}: Props) {
  const { t } = useTranslation();

  const getMeetingSlotsValue = useCallback(
    (meetingSlots: Slot[]) => {
      const dayLabels: Record<number, string> = {
        0: t("Sunday"),
        1: t("Monday"),
        2: t("Tuesday"),
        3: t("Wednesday"),
        4: t("Thursday"),
        5: t("Friday"),
        6: t("Saturday"),
      };
      const scheduleDays: number[] = uniq(
        meetingSlots.map((ms) => ms.day),
      ).sort((a, b) => a - b);

      const values: string[] = [];
      let startIdx = 0;
      while (startIdx < scheduleDays.length) {
        // If the next day isn't consecutive, push and move to the next day
        if (scheduleDays[startIdx] + 1 !== scheduleDays[startIdx + 1]) {
          values.push(dayLabels[scheduleDays[startIdx]]);
          startIdx++;
          continue;
        }
        let checkIdx = startIdx;
        while (scheduleDays[checkIdx] + 1 === scheduleDays[checkIdx + 1]) {
          checkIdx++;
        }
        values.push(
          `${dayLabels[scheduleDays[startIdx]]} - ${dayLabels[scheduleDays[checkIdx]]}`,
        );
        startIdx = checkIdx + 1;
      }
      const start = Math.min(
        ...meetingSlots.map(
          (ms) => ms.hour * 60 * 60 * 1000 + ms.minute * 60 * 1000,
        ),
      );
      const end = Math.max(
        ...meetingSlots.map(
          (ms) =>
            ms.hour * 60 * 60 * 1000 + ms.minute * 60 * 1000 + ms.duration,
        ),
      );
      return `${values.join(", ")} • ${hourMinutes(new Date(0, 0, 0).setMilliseconds(start))} - ${hourMinutes(new Date(0, 0, 0).setMilliseconds(end))}`;
    },
    [t],
  );

  const getMeetingTypesValue = useCallback(
    (meetingTypes: MeetingType[], privacyLevels: PrivacyLevel[]): string => {
      // Only consider the first privacy level
      // - this is because although privacy levels
      // is an array, a schedule can only have one
      const privacyLevel = privacyLevels[0];
      // Special handling when there is a combined
      // meeting type (e.g. video call and in-person visit)
      // schedule
      if (
        meetingTypes.length === 2 &&
        meetingTypes.every((mt) =>
          [MeetingType.VideoCall, MeetingType.InPersonVisit].includes(mt),
        )
      )
        return privacyLevel === PrivacyLevel.Monitored
          ? t("Monitored video calls and in-person visits")
          : t("Confidential video calls and in-person visits");
      // Voice calls must be distinct schedules
      if (meetingTypes.every((mt) => [MeetingType.VoiceCall].includes(mt)))
        return privacyLevel === PrivacyLevel.Monitored
          ? t("Monitored voice calls")
          : t("Confidential voice calls");
      // Webinars must be distinct schedules
      if (meetingTypes.every((mt) => [MeetingType.Webinar].includes(mt)))
        return privacyLevel === PrivacyLevel.Monitored
          ? t("Monitored webinars")
          : t("Confidential webinars");

      Sentry.captureMessage(
        `Unexpected meeting types schedule combination: ${meetingTypes.join(",")}`,
        "error",
      );

      // The default case (applies for example to a schedule of
      // just video calls or in-person visits)
      return t("{{privacyLevel}} {{meetingTypes}}", {
        privacyLevel: labelPrivacyLevel(privacyLevel, { titleCase: true }),
        meetingTypes: meetingTypes
          .map((mt) => labelMeetingType(mt, { plural: true }))
          .join(", "),
      });
    },
    [t],
  );

  return (
    <Card
      variant="outlined"
      sx={{
        px: 2,
        py: 1.5,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          color:
            schedule.endsOn && isPast(getRawDateTzUnaware(schedule.endsOn))
              ? "text.disabled"
              : "text.primary",
        }}
      >
        <Stack spacing={1.5}>
          <Typography variant="h3">{schedule.name}</Typography>
          <Stack direction="row" gap={1.5}>
            {schedule.privacyLevels.includes(PrivacyLevel.Monitored) ? (
              <RemoveRedEyeOutlined fontSize="small" />
            ) : (
              <LockOutlined fontSize="small" />
            )}
            <Typography variant="body1">
              {getMeetingTypesValue(
                schedule.meetingTypes,
                schedule.privacyLevels,
              )}
            </Typography>
          </Stack>
          {!!schedule.groups.length && (
            <Stack direction="row" gap={1.5}>
              <RoomOutlined fontSize="small" />
              <Typography variant="body1">
                {alphabetize(schedule.groups.map((s) => s.name)).join(", ")}
              </Typography>
            </Stack>
          )}
          {!!schedule.meetingSlots.length && (
            <Stack direction="row" gap={1.5}>
              <EventOutlined fontSize="small" />
              <Typography variant="body1">
                {getMeetingSlotsValue(schedule.meetingSlots)}
              </Typography>
            </Stack>
          )}
          {(!!schedule.startsOn || !!schedule.endsOn) && (
            <Stack direction="row" gap={1.5}>
              <DateRangeOutlined fontSize="small" />
              <Typography variant="body1">
                {!!schedule.startsOn && !!schedule.endsOn
                  ? `${yearShortMonthDate(getRawDateTzUnaware(schedule.startsOn))} -
                ${yearShortMonthDate(getRawDateTzUnaware(schedule.endsOn))}`
                  : schedule.startsOn
                    ? t("Effective from {{date}}", {
                        date: yearShortMonthDate(
                          getRawDateTzUnaware(schedule.startsOn),
                        ),
                      })
                    : schedule.endsOn
                      ? t("Effective until {{date}}", {
                          date: yearShortMonthDate(
                            getRawDateTzUnaware(schedule.endsOn),
                          ),
                        })
                      : null}
              </Typography>
            </Stack>
          )}
        </Stack>
        <ContextMenu id={`${schedule.id}-actions`}>
          {canEdit
            ? [
                <MenuItem key="edit" onClick={onEdit}>
                  {t("Edit schedule")}
                </MenuItem>,
                <MenuItem
                  key="delete"
                  onClick={onDelete}
                  sx={{
                    color: (theme) => theme.palette.error.dark,
                  }}
                >
                  {t("Delete schedule")}
                </MenuItem>,
              ]
            : [
                <MenuItem key="details" onClick={onEdit}>
                  {t("Details")}
                </MenuItem>,
              ]}
        </ContextMenu>
      </Box>
    </Card>
  );
}
