import { partition, sortByNumbers } from "@ameelio/core";
import {
  Button,
  Dialog,
  MultiSelectChipInputBase,
  SelectInputBase,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, CircularProgress, SelectChangeEvent } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  MeetingType,
} from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import { BASE_HISTORY_FIELDS } from "@src/lib/Call";
import { alphabetize, capitalize } from "@src/lib/Common";
import DateRangePicker from "@src/lib/DateRangePicker";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import featuresForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import stripInvalid from "@src/lib/stripInvalid";
import { downloadCSV } from "@src/lib/toCSV";
import useEntitlement from "@src/lib/useEntitlement";
import { useFetchAllPages } from "@src/lib/useFetchAllPages";
import { endOfDay, endOfYesterday, subWeeks } from "date-fns";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExportMeetingsButton from "src/lib/ExportMeetings";
import Header from "../../lib/Header";
import ScheduledMeetingsTable from "../UpcomingMeetingsPage/ScheduledMeetingsTable";
import { exportDateRange, writePhoneCall } from "./export";
import { GetFacilityPhoneCallsDocument } from "./GetFacilityPhoneCalls.generated";
import { GetMeetingsHistoryDocument } from "./GetMeetingsHistory.generated";

type Filters = {
  scheduledStartAfter: number | undefined;
  scheduledStartBefore: number;
  meetingStatus: MeetingStatus[];
  meetingType: MeetingType | "PHONE_CALLS";
  group: string[] | undefined;
};

const LIMIT = 100;

const scheduledMeetingStatuses = [
  MeetingStatus.Rejected,
  MeetingStatus.Cancelled,
  MeetingStatus.Ended,
  MeetingStatus.NoShow,
  MeetingStatus.Terminated,
];
const unscheduledMeetingStatuses = [
  MeetingStatus.Ended,
  MeetingStatus.NoShow,
  MeetingStatus.Terminated,
];
const meetingStatusFilters: Record<MeetingType, MeetingStatus[]> = {
  [MeetingType.InPersonVisit]: scheduledMeetingStatuses,
  [MeetingType.VideoCall]: scheduledMeetingStatuses,
  [MeetingType.VoiceCall]: unscheduledMeetingStatuses,
  [MeetingType.Webinar]: scheduledMeetingStatuses,
};

function featuresForMeetingTypeFilter(
  meetingType: Filters["meetingType"],
): FacilityFeature[] {
  return meetingType === "PHONE_CALLS"
    ? [FacilityFeature.PhoneCall]
    : featuresForMeetingType(meetingType);
}

const { history } = window;

// load state from history (back nav)
function parseHistoryState() {
  return stripInvalid(
    Joi.object<{
      scheduledStartAfter?: number;
      scheduledStartBefore?: number;
      meetingStatus?: MeetingStatus[];
      meetingType?: MeetingType | "PHONE_CALLS";
      group?: string[];
    }>({
      scheduledStartAfter: Joi.number(),
      scheduledStartBefore: Joi.number(),
      meetingStatus: Joi.array().items(
        Joi.string().valid(...Object.values(MeetingStatus)),
      ),
      meetingType: Joi.string().valid(
        ...Object.values(MeetingType),
        "PHONE_CALLS",
      ),
      group: Joi.array().items(Joi.string()),
    }),
    history.state,
  );
}

export default function MeetingsHistoryPage() {
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);

  const [enabledMeetingTypes, disabledMeetingTypes] = partition(
    [...Object.values(MeetingType), "PHONE_CALLS"] as const,
    (mt) =>
      facility.features.some((f) =>
        featuresForMeetingTypeFilter(mt).includes(f),
      ),
  );
  const sortedMeetingTypes = [
    ...alphabetize(enabledMeetingTypes),
    ...alphabetize(disabledMeetingTypes),
  ];

  const [filters, setFilters] = useState<Filters>(() => {
    const historyState = parseHistoryState();
    return {
      meetingStatus: historyState.meetingStatus
        ? historyState.meetingStatus
        : [],
      scheduledStartAfter:
        historyState.scheduledStartAfter ||
        subWeeks(endOfYesterday(), 1).getTime(),
      scheduledStartBefore:
        historyState.scheduledStartBefore || endOfYesterday().getTime(),
      meetingType: historyState.meetingType || sortedMeetingTypes[0],
      group: historyState.group,
    };
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const handleApolloError = useApolloErrorHandler();

  const {
    loading: loadingMeetings,
    data: meetingData,
    refetch: refetchMeetings,
  } = useQuery(GetMeetingsHistoryDocument, {
    skip: filters.meetingType === "PHONE_CALLS",
    variables: {
      cursor: undefined,
      id: facility.id,
      last: LIMIT,
      wantRecordings: canReviewRecordings,
      ...filters,
      // we coerce here because the incompatible meeting type is
      // skipped above and typescript doesn't know
      meetingType: filters.meetingType as MeetingType,
    },
    onError: handleApolloError,
  });
  const meetings = meetingData
    ? sortByNumbers(
        meetingData.facility.meetings.edges,
        (m) => m.node.interval.startAt * -1,
      ).map((e) => e.node)
    : [];

  const {
    loading: loadingPhoneCalls,
    data: phoneData,
    refetch: refetchPhoneCalls,
  } = useQuery(GetFacilityPhoneCallsDocument, {
    skip: filters.meetingType !== "PHONE_CALLS",
    variables: {
      cursor: undefined,
      id: facility.id,
      last: LIMIT,
      scheduledStartAfter: filters.scheduledStartAfter,
      scheduledStartBefore: filters.scheduledStartBefore,
    },
    onError: handleApolloError,
  });
  const phoneCalls = phoneData
    ? sortByNumbers(
        phoneData.facility.phoneCalls.edges,
        (m) => m.node.start * -1,
      ).map((e) => ({
        ...e.node,
        interval: { startAt: e.node.start },
        inmates: [e.node.inmate],
      }))
    : [];

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  useEffect(() => {
    const { meetingType, ...rest } = filters;
    if (meetingType === "PHONE_CALLS") void refetchPhoneCalls({ ...rest });
    else void refetchMeetings({ ...rest, meetingType });
  }, [refetchMeetings, refetchPhoneCalls, filters]);

  const exportName = () =>
    `${facility.name.replace(" ", "_")}_${
      filters.meetingType
    }_${filters.meetingStatus.join("_")}_${exportDateRange(
      filters.scheduledStartAfter,
      filters.scheduledStartBefore,
    )}`;

  const { working: fetchingPhoneCalls, fetchAllPages: exportPhoneCalls } =
    useFetchAllPages({
      query: GetFacilityPhoneCallsDocument,
      variables: {
        cursor: undefined,
        id: facility.id,
        last: LIMIT,
        scheduledStartAfter: filters.scheduledStartAfter,
        scheduledStartBefore: filters.scheduledStartBefore,
      },
      getQueryData: (r) => r.facility.phoneCalls,
      onComplete: (calls) =>
        downloadCSV(
          sortByNumbers(calls, (c) => c.start)
            .reverse()
            .map(writePhoneCall),
          exportName(),
        ),
    });

  const loading = loadingMeetings || loadingPhoneCalls;

  return (
    <Box>
      <Header
        title={t("History")}
        subtitle={t(
          "Review the history of visits, video calls, voice calls, and more. You can search by changing criteria in the fields below.",
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <SelectInputBase
                size="small"
                disabled={loading}
                aria-label={t("Filter by meeting type")}
                style={{ width: "auto" }}
                placeholder={t("Filter by meeting type")}
                label={t("Meeting type")}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  changeFilter({
                    meetingType: event.target.value as MeetingType,
                    meetingStatus: [],
                  });
                }}
                value={filters.meetingType}
                items={sortedMeetingTypes.map((mt) => ({
                  value: mt,
                  key: mt,
                  name:
                    mt === "PHONE_CALLS"
                      ? t("Outside services")
                      : meetingTypeTitle([mt]),
                  disabled: !facility.features.some((f) =>
                    featuresForMeetingTypeFilter(mt).includes(f),
                  ),
                }))}
              />
              {filters.meetingType !== "PHONE_CALLS" && (
                <MultiSelectChipInputBase
                  size="small"
                  disabled={loading}
                  label={t("Status")}
                  aria-label={t("Filter by status")}
                  value={filters.meetingStatus}
                  onChange={(event) => {
                    setFilters((filters) => ({
                      ...filters,
                      meetingStatus: event.target.value as MeetingStatus[],
                    }));
                  }}
                  items={meetingStatusFilters[filters.meetingType].map(
                    (status) => ({
                      value: status,
                      key: status,
                      name: getMeetingStatusLabelByType(
                        status,
                        filters.meetingType as MeetingType,
                      ),
                    }),
                  )}
                />
              )}
              <SelectInputBase
                size="small"
                disabled={loading}
                label={t("Location")}
                aria-label={t("Filter by resident location")}
                value={filters.group?.length === 1 ? filters.group : "All"}
                onChange={(event) => {
                  setFilters((filters) => ({
                    ...filters,
                    group:
                      event.target.value === "All"
                        ? undefined
                        : ([event.target.value] as string[]),
                  }));
                }}
                items={
                  facility.groups.length > 1
                    ? [
                        {
                          value: "All",
                          name: t("All"),
                        },
                        ...facility.groups
                          .map((g) => ({
                            value: g.id,
                            key: g.id,
                            name: capitalize(g.name),
                          }))
                          .sort((a, b) => a.name.localeCompare(b.name)),
                      ]
                    : [
                        ...facility.groups.map((g) => ({
                          value: g.id,
                          key: g.id,
                          name: capitalize(g.name),
                        })),
                      ]
                }
              />
              <DateRangePicker
                onStartDateChange={(date) => {
                  changeFilter({
                    scheduledStartAfter: date || undefined,
                  });
                }}
                onEndDateChange={(date) => {
                  const newDate = date ? endOfDay(date) : new Date();
                  changeFilter({
                    scheduledStartBefore: newDate.getTime(),
                  });
                }}
                disabled={loading}
                startDate={filters.scheduledStartAfter || null}
                endDate={filters.scheduledStartBefore || null}
              />
            </ResponsiveColumns>
          </Box>
          {filters.meetingType !== "PHONE_CALLS" ? (
            <ExportMeetingsButton
              key="filter-meeting-export"
              facility={facility}
              defaultFields={BASE_HISTORY_FIELDS}
              filters={{
                ...filters,
                group: !filters.group
                  ? null
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    facility.groups.find((g) => g.id === filters.group![0])!,
                scheduledStartAfter:
                  filters.scheduledStartAfter || new Date(2023, 1, 1).getTime(),
                meetingType: [filters.meetingType],
              }}
              disabled={loading || !meetings.length}
            >
              {t("Export data")}
            </ExportMeetingsButton>
          ) : (
            <Button
              key="filter-meeting-export"
              variant="outlined"
              disabled={loading || phoneCalls.length === 0}
              onClick={async () => await exportPhoneCalls()}
              sx={{ ml: 2 }}
            >
              {t("Export data")}
            </Button>
          )}
        </Box>
      </Header>
      <Box p={3}>
        <ScheduledMeetingsTable
          loading={loading}
          meetings={
            filters.meetingType === "PHONE_CALLS" ? phoneCalls : meetings
          }
          enableBatchCancel={false}
        />
        {(meetingData?.facility.meetings.pageInfo.hasPreviousPage ||
          phoneData?.facility.phoneCalls.pageInfo.hasPreviousPage) &&
          !loading && (
            <Alert severity="warning">
              {t(
                "Only the most recent {{limit}} results are shown. Please refine your search or export to view all results.",
                { limit: LIMIT },
              )}
            </Alert>
          )}
        {fetchingPhoneCalls && (
          <Dialog
            title={t("Export in progress...")}
            style={{ padding: "16px" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={2}
            >
              <CircularProgress />
            </Box>
          </Dialog>
        )}
      </Box>
    </Box>
  );
}
